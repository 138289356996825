import './bootstrap';
import '../css/app.css';
import moment from 'moment';

import { createApp, h } from 'vue';
import { createInertiaApp, Link, Head } from '@inertiajs/vue3';

import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

import { useToast } from 'vue-toast-notification';
// Import one of the available themes
import 'vue-toast-notification/dist/theme-default.css';
// import 'vue-toast-notification/dist/theme-bootstrap.css';

import VueSweetalert2 from 'vue-sweetalert2';
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';

import Vapor from 'laravel-vapor'
Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL)

window.Vapor = Vapor
window.useToast = useToast();
window.Swal = Swal;

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'SMSNOVA';

import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
});

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(VueSweetalert2)
            .component('Link', Link)
            .component('Head', Head)
            .component('VueDatePicker', VueDatePicker)
            .component('VueMultiselect', VueMultiselect)
            .mixin({
                methods: {
                    asset: window.Vapor.asset,
                    can(permission) {
                        return this.$page.props.permissions.find((userPermission) => userPermission == permission);
                    },
                    role(role) {
                        return this.$page.props.role.find((userRole) => userRole == role);
                    },
                    isCustomerAdmin() {
                        return this.$page.props.role.find((userRole) => userRole == 'customer_admin');
                    },
                    viewDateTimeFormat(v) {
                        return moment(v).format('DD/MM/YYYY HH:mm');
                    },
                    onlyNumberInput(evt) {
                        evt = evt ? evt : window.event;
                        var charCode = evt.which ? evt.which : evt.keyCode;
                        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                            evt.preventDefault();
                        } else {
                            return true;
                        }
                    },
                    genArrowPagination(txt) {
                        if (txt.includes('Next')) {
                            return '>>';
                        }

                        if (txt.includes('Previous')) {
                            return '<<';
                        }

                        return txt;
                    }
                }
            })
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});